<template>
  <div class="list-wrapper">
    <div class="header">选择岗位</div>
    <ul class="job-list">
      <li
        class="item"
        v-for="(item, index) in orgAndPostList"
        @click="selectPost(item, index)"
        :key="index"
      >
        <div class="job">
          <div class="name">{{ item.postTitle }}</div>
          <div class="relegation">{{ item.orgTitle }}</div>
        </div>
        <div class="select">
          <i v-if="item.defaultFlag === 1" class="iconfont icon-joyo">
            &#xe728;
          </i>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'select-identity',
  computed: {
    ...mapGetters(['orgAndPostList'])
  },
  methods: {
    selectPost(item) {
      this.orgAndPostList.forEach(item => (item.defaultFlag = 0));
      item.defaultFlag = 1;
      this.setRoleList(item.roleList);
      this.setPostData({ orgId: item.orgId, postId: item.postId });
      this.$router.push({ name: 'select-role' });
    },
    ...mapMutations({
      setRoleList: 'SET_ROLE_LIST',
      setPostData: 'SET_POST_DATA'
    })
  }
};
</script>

<style lang="scss" scoped>
.list-wrapper {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  background: $color-F7;

  .header {
    padding: 18px 14px;
    font-size: 15px;
    font-weight: 400;
    color: $color-999;
  }
  .job-list {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 14px;
      margin-bottom: 10px !important;
      background: $color-FFF;

      .job {
        flex: 1;
        .name {
          margin-bottom: 9px;
          font-size: 15px;
          font-weight: 400;
          color: $color-333;
        }
        .relegation {
          font-size: 14px;
          font-weight: 400;
          color: $color-999;
        }
      }
      .select {
        width: 30px;
        text-align: right;
        font-size: 28px;
        color: $color-theme;
      }
    }
  }
}
</style>
